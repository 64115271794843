import "./App.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import Terms from "./pages/Terms";
import Help from "./pages/Help";
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";
import Header from "./components/Header";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  return (
    <div
      className="min-vh-100 d-flex flex-column 
    justify-content-between content-container-dark"
    >
      <QueryClientProvider client={queryClient}>
        <div>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="help" element={<Help />} />
            <Route path="about" element={<About />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
          </Routes>
        </div>
        <Footer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
