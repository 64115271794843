import axios from "axios";
const BASE_URL = "https://api.mbilly.ug/v1/";
//const BASE_URL = "http://localhost:3004/v1/";

export const mainApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": true,
  },
});

mainApi.defaults.headers.common["Content-Type"] = "application/json";

// mainApi.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers["authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const getProductList = async () => {
  const response = await mainApi.get(`products`);
  return response.data;
};

export const getPriceList = async (params) => {
  const response = await mainApi.get(
    `products/price-list?code=${params?.code}`
  );
  return response.data;
};

export const getChoiceList = async (params) => {
  const response = await mainApi.get(
    `products/choice-list?code=${params?.code}`
  );
  return response.data;
};

export const validateProduct = async (params) => {
  const response = await mainApi.post(
    "products/validate",
    JSON.stringify(params)
  );
  return response.data;
};

export const getProductPurchaseStatus = async (params) => {
  const response = await mainApi.get(
    `products/status?reference=${params?.reference}`
  );
  return response.data;
};

export const initiateMobileMoney = async (params) => {
  const response = await mainApi.post("collections", JSON.stringify(params));
  return response.data;
};

export const getCharges = async (params) => {
  const response = await mainApi.get(`charges?code=${params?.code}`);
  return response.data;
};

export const getSettings = async () => {
  const response = await mainApi.get(`settings`);
  return response.data;
};
