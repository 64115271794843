import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import {
  getChoiceList,
  getPriceList,
  validateProduct,
  initiateMobileMoney,
  getProductPurchaseStatus,
  getCharges,
} from "../api/mainApi";
import { useQuery, useMutation } from "react-query";

import { Functions } from "../helpers";

const Pay = ({ show, setShow, productData, setProductData, settings }) => {
  //state
  const [choiceEnabled, setChoiceEnabled] = useState(false);
  const [priceEnabled, setPriceEnabled] = useState(false);
  const [chargesEnabled, setChargesEnabled] = useState(false);
  const [step, setStep] = useState(1);
  const [purchaseError, setPurchaseError] = useState(null);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(null);
  //const [validationReference, setValidationReference] = useState(null);
  const [productReference, setProductReference] = useState(null);

  // Queries
  const {
    isLoading: isLoadingChoice,
    isError: isErrorChoice,
    isSuccess: isSuccessChoice,
    data: dataChoice,
    error: errorChoice,
  } = useQuery({
    queryKey: ["choiceList", productData],
    queryFn: async () => await getChoiceList({ code: productData?.code }),
    retry: false,
    enabled: choiceEnabled,
  });

  const {
    isLoading: isLoadingPrice,
    isError: isErrorPrice,
    isSuccess: isSuccessPrice,
    data: dataPrice,
    error: errorPrice,
  } = useQuery({
    queryKey: ["priceList", productData],
    queryFn: async () => await getPriceList({ code: productData?.code }),
    retry: false,
    enabled: priceEnabled,
  });

  const {
    isLoading: isLoadingCharge,
    isError: isErrorCharge,
    isSuccess: isSuccessCharge,
    data: dataCharge,
    error: errorCharge,
  } = useQuery({
    queryKey: ["charges", productData],
    queryFn: async () => await getCharges({ code: productData?.code }),
    retry: false,
    enabled: chargesEnabled,
  });

  //Mutate validate product
  const validateProductMutation = useMutation({
    mutationFn: (data) => validateProduct(data),
    onSuccess: (dataResponse) => {
      if (dataResponse?.data?.status === "validated") {
        //setValidationReference(dataResponse?.data?.validation_reference);
        setProductReference(dataResponse?.data?.reference);
        setPurchaseSuccess(
          `Payment of UGX ${parseInt(amountWatch)} for ${
            productData?.name
          }, account name ${
            dataResponse?.data?.customer_name
          } to be charged to ${contactPhoneWatch} at a fee of  ${
            watchTotalCollection - amountWatch
          } UGX.`
        );
        setPurchaseError(null);
      } else {
        setStep(1);
        setPurchaseError(
          "Validation failed, please input the correct details."
        );
        setPurchaseSuccess(null);
      }
    },
    onError(error) {
      setStep(1);
      setPurchaseError("Validation failed, please input the correct details.");
      setPurchaseSuccess(null);
    },
  });

  //Mutate initiate mobile money
  const initiateMobileMoneyMutation = useMutation({
    mutationFn: (data) => initiateMobileMoney(data),
    onSuccess: (dataResponse) => {
      if (dataResponse?.data?.success === true) {
        setStep(3);
        setPurchaseSuccess(
          "Mobile Money request initiated. Confirm payment prompt on your phone number."
        );
        setPurchaseError(null);
        //onCheckPurchaseStatus();
      } else {
        setStep(3);
        setPurchaseError(
          "Failed to initiate mobile money payment, check that the number provided is correct."
        );
        setPurchaseSuccess(null);
      }
    },
    onError(error) {
      setStep(3);
      setPurchaseError(
        "Failed to initiate mobile money payment, check that the number provided is correct."
      );
      setPurchaseSuccess(null);
    },
  });

  // Queries
  const { isLoading: isLoadingStatus, data: dataStatus } = useQuery({
    queryKey: ["purchase-status", productReference],
    queryFn: async () =>
      await getProductPurchaseStatus({ reference: productReference }),
    onSuccess: (dataStatus) => {
      if (dataStatus?.data?.status === "success") {
        setStep(3);
        setPurchaseSuccess("Product purchased successfully.");
        setPurchaseError(null);
        setPurchaseComplete(true);
      } else if (dataStatus?.data?.status === "failed") {
        setStep(3);
        setPurchaseSuccess(null);
        setPurchaseError("Product purchase failed");
        setPurchaseComplete(true);
      }
    },
    onError: (errorStatus) => {
      setStep(3);
      setPurchaseSuccess(null);
      setPurchaseError("Product purchase failed");
      setPurchaseComplete(true);
    },
    enabled: initiateMobileMoneyMutation.isSuccess,
    //Keep refetching every 5 seconds while we don't stop it
    refetchInterval: purchaseComplete ? false : 4000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });

  //Set whether price query or choice query runs
  useEffect(() => {
    setChargesEnabled(true);
    if (productData?.has_choice_list === true) {
      setChoiceEnabled(true);
      setValue("product_code", productData?.code);
    } else if (productData?.has_price_list === true) {
      setPriceEnabled(true);
    } else {
      setChoiceEnabled(false);
      setPriceEnabled(false);
      setValue("product_code", productData?.code);
    }
  }, [productData]);

  //METHODS
  const handleClose = () => {
    setProductData(null);
    setChoiceEnabled(false);
    setPriceEnabled(false);
    setStep(1);
    setPurchaseError(null);
    setPurchaseSuccess(null);
    //setValidationReference(null);
    reset();
    setShow(false);
  };

  const generateRandomString = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const calculateTotalService = (providerCharge, amount) => {
    let totalCharge = providerCharge + amount;
    return totalCharge;
  };

  const calculateTotalCollection = (
    providerCharge,
    mbillyPercentageCharge,
    amount
  ) => {
    let totalAmount = providerCharge + amount;
    let totalCharge = Math.ceil(
      totalAmount / (1 - mbillyPercentageCharge / 100)
    );
    return totalCharge;
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      reference: "",
      msisdn: "",
      amount: "",
      provider_charge: "",
      product_code: "",
      contact_phone: "",
      location_id: null,
      total_collection: "",
      total_product: "",
    },
  });

  const productCodeWatch = watch("product_code");
  const amountWatch = watch("amount");
  const contactPhoneWatch = watch("contact_phone");
  const providerChargeWatch = watch("provider_charge");
  const watchTotalProduct = watch("total_product");
  const watchTotalCollection = watch("total_collection");

  useEffect(() => {
    if (productData?.has_price_list && !isLoadingPrice) {
      const filteredProduct = dataPrice?.data?.filter(
        (productPrice) => productPrice.code === productCodeWatch
      );
      setValue("amount", filteredProduct[0]?.price);
    }
  }, [productCodeWatch]);

  //Calculate provider charge when amount changes
  useEffect(() => {
    setValue(
      "provider_charge",
      Functions.getProviderCharge(amountWatch, dataCharge?.data)
    );
  }, [amountWatch]);

  useEffect(() => {
    let providerChargeInt = parseInt(providerChargeWatch);
    let amountInt = parseInt(amountWatch);
    const totalProduct = calculateTotalService(providerChargeInt, amountInt);
    const totalCollection = calculateTotalCollection(
      providerChargeInt,
      parseFloat(settings?.percentage_fee),
      amountInt
    );
    setValue("total_collection", totalCollection);
    setValue("total_product", totalProduct);
  }, [amountWatch, providerChargeWatch]);

  const onSubmit = (data) => {
    const params = {
      msisdn: data.msisdn,
      amount: watchTotalProduct,
      product_code: data.product_code,
      contact_phone: data.contact_phone,
      location_id: data.location_id,
      reference: generateRandomString(15),
    };
    validateProductMutation.mutate(params);
    setStep(2);
  };

  const onInitiateMobileMoney = () => {
    const phoneNumber = `+256${contactPhoneWatch.substring(1)}`;
    const params = {
      reference: `CL${productReference}`,
      msisdn: phoneNumber,
      currency: "UGX",
      amount: watchTotalCollection,
      description: `Pay bill for ${productData?.name}`,
    };
    initiateMobileMoneyMutation.mutate(params);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="card-bg-dark" closeVariant="white">
          <Modal.Title style={{ color: "#ffffff" }}>
            {productData?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="card-bg-dark text-white">
          {/* <p>Step: {step}</p> */}
          {purchaseError !== null && (
            <Alert variant="warning">{purchaseError}</Alert>
          )}
          <div>
            <img
              src={Functions.generateImage(productData?.code)}
              alt={productData?.name}
              width="30%"
            />
          </div>
          {step === 1 ? (
            <Form className="mt-4">
              {productData?.has_choice_list === true ? (
                <>
                  <Form.Group className="mb-3" controlId="accountId">
                    <Form.Label>Select Choice</Form.Label>
                    {dataChoice?.data?.length > 0 && (
                      <Form.Select
                        aria-label="Product Choice"
                        className="bg-white"
                        {...register("location_id")}
                      >
                        <option>Product Choice</option>
                        {dataChoice?.data?.map((item, index) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </Form.Select>
                    )}
                  </Form.Group>
                </>
              ) : productData?.has_price_list === true ? (
                <>
                  <Form.Group className="mb-3" controlId="accountId">
                    <Form.Label>Select Price</Form.Label>
                    {dataPrice?.data?.length > 0 && (
                      <Form.Select
                        aria-label="Product Price"
                        className="bg-white"
                        {...register("product_code")}
                      >
                        <option>Product Price</option>
                        {dataPrice?.data?.map((item, index) => {
                          return (
                            <option value={item?.code}>{item?.name}</option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </Form.Group>
                </>
              ) : null}
              <Form.Group className="mb-3" controlId="accountId">
                <Form.Label>
                  {Functions.setMsisdnLabel(productData?.code)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={Functions.setMsisdnPlaceholder(
                    productData?.code
                  )}
                  className="bg-white"
                  {...register("msisdn", {
                    required: `${Functions.setMsisdnLabel(
                      productData?.code
                    )} is required`,
                  })}
                />
                {errors?.msisdn && (
                  <p className="text-danger mt-2 fs-5">
                    {errors?.msisdn?.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="amountId">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Amount"
                  className="bg-white"
                  disabled={productData?.has_price_list}
                  {...register("amount", {
                    required: "Amount is required",
                    min: {
                      value: 500,
                      message: "Amount cannot be less than UGX 500",
                    },
                    max: {
                      value: 10000000,
                      message: "Amount cannot be more than UGX 10,000,000",
                    },
                  })}
                />
                {errors?.amount && (
                  <p className="text-danger mt-2 fs-5">
                    {errors?.amount?.message}
                  </p>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNumberId">
                <Form.Label>
                  Mobile Money Account to bill (Only MTN & Airtel Accepted)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  className="bg-white"
                  {...register("contact_phone", {
                    required: "MoMo Pay/ Airtel Money phone number required.",
                    validate: {
                      checkNumber: (v) =>
                        Functions.checkNumber(v) ||
                        "Only valid MTN and Airtel numbers supported.",
                    },
                    maxLength: {
                      value: 10,
                      message: "Phone number must be 10 characters.",
                    },
                    minLength: {
                      value: 9,
                      message: "Phone number must be 10 characters.",
                    },
                  })}
                />
                {errors?.contact_phone && (
                  <p className="text-danger mt-2 fs-5">
                    {errors?.contact_phone?.message}
                  </p>
                )}
              </Form.Group>
            </Form>
          ) : step === 2 ? (
            <>
              <p className="text-white">{purchaseSuccess}</p>
              {validateProductMutation?.isLoading && (
                <Spinner role="status" animation="grow" variant="light">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </>
          ) : (
            <>
              {purchaseComplete === false && (
                <Spinner role="status" animation="grow" variant="light">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
              <p className="text-white mt-2">{purchaseSuccess}</p>
            </>
          )}
        </Modal.Body>
        {step === 1 ? (
          <Modal.Footer className="card-bg-dark">
            <Button
              variant="primary"
              onClick={handleClose}
              className="text-white rounded-pill"
            >
              Close
            </Button>
            <Button
              variant="dark"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className="text-white rounded-pill"
            >
              Submit
            </Button>
          </Modal.Footer>
        ) : step === 2 ? (
          <Modal.Footer className="card-bg-dark">
            <Button
              variant="primary"
              onClick={handleClose}
              className="text-white rounded-pill"
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              type="submit"
              onClick={() => onInitiateMobileMoney()}
              className="text-white rounded-pill"
            >
              Confirm
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
};

export default Pay;
