import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Image Assets
import CULIPA_LOGO from "../images/culipa_logo.png";

function Footer() {
  return (
    <footer className="pt-4 always-blue-bg mt-4">
      <Container>
        <Row>
          <Col xs={6} md={6} lg={3}>
            <div className="d-flex flex-column  align-items-start">
              <h5 className="text-secondary mb-3">Powered by</h5>
              <img src={CULIPA_LOGO} alt="Culipa Logo" width="100px" />
            </div>
          </Col>
          <Col xs={6} md={6} lg={3}>
            <div className="d-flex flex-column align-items-start">
              <h5 className="text-secondary mb-3">Quick Links</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home" className="text-white">
                  Home
                </Nav.Link>
                <Nav.Link eventKey="link-1" className="text-white">
                  Pricing
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  Terms & Conditions
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  About Us
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  Help
                </Nav.Link>
              </Nav>
            </div>
          </Col>
          <Col xs={6} md={6} lg={3}>
            <div className="d-flex flex-column align-items-start">
              <h5 className="text-secondary mb-3">Culipa Products</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link eventKey="link-1" className="text-white">
                  Plej
                </Nav.Link>
                <Nav.Link eventKey="link-1" className="text-white">
                  Bulkpay
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  Lipi
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  Mbilly
                </Nav.Link>
              </Nav>
            </div>
          </Col>
          <Col xs={6} md={6} lg={3}>
            <div className="d-flex flex-column align-items-start">
              <h5 className="text-secondary mb-3">Contact Us</h5>
              <Nav defaultActiveKey="/home" className="flex-column">
                <Nav.Link href="/home" className="text-white">
                  P.O Box 16060, Kampala, Uganda
                </Nav.Link>
                <Nav.Link eventKey="link-1" className="text-white">
                  +256 393 246 095
                </Nav.Link>
                <Nav.Link eventKey="link-2" className="text-white">
                  mail@culipa.com
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="d-flex flex-column flex-sm-row justify-content-between py-2 border-top align-items-center">
          <Col xs={6} sm={6}>
            <p className="text-light">© 2024 Culipa LTD</p>
          </Col>
          <Col xs={6} sm={6}>
            <Nav className="justify-content-center justify-content-sm-end">
              <Nav.Item>
                <Nav.Link href="/terms" className="px-2 text-white">
                  Terms
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/privacy" className="px-2 text-white">
                  Privacy
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
