import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import { getProductList, getSettings } from "../api/mainApi";
import { useQuery } from "react-query";
import Pay from "../components/Pay";
import { ArrowRight, Search } from "react-bootstrap-icons";
import AirtelMoney from "../images/airtel_money.png";
import Momo from "../images/mtn_mobile_money.png";
import Visa from "../images/visa.png";
import Mastercard from "../images/mastercard.png";

import { Functions } from "../helpers";

const Landing = () => {
  //state
  const [showPay, setShowPay] = useState(false);
  const [productList, setProductList] = useState(null);
  const [productData, setProductData] = useState(null);
  const [recent, setRecent] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [settingsEnabled, setSettingsEnabled] = useState(false);
  const [latestSettings, setLatestSettings] = useState(null);
  // Queries
  const { isLoading, isError, isSuccess, data, error } = useQuery({
    queryKey: ["productList"],
    queryFn: async () => await getProductList(),
    retry: false,
    //enabled: true,
  });

  const {
    isLoading: isLoadingSettings,
    isError: isErrorSettings,
    isSuccess: isSuccessSettings,
    data: dataSettings,
    error: errorSettings,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: async () => await getSettings(),
    retry: false,
    enabled: settingsEnabled,
  });

  useEffect(() => {
    if (isSuccess) {
      const filteredProducts = data?.data?.filter(
        (product) => product.category !== "BANK_TRANSFERS"
      );
      const sortedProducts = sortProductOrder(filteredProducts);
      setProductList(sortedProducts);
    }
  }, [data, isSuccess, isError, searchParam]);

  useEffect(() => {
    searchForProducts(searchParam);
  }, [searchParam]);

  const getRecentProducts = () => {
    var recentProducts = JSON.parse(localStorage.getItem("recent-products"));
    if (recentProducts?.length > 0) {
      setRecent(recentProducts);
    } else {
      setRecent([]);
    }
  };

  const setRecentProducts = (product) => {
    const found = recent.some((el) => el.name === product?.name);
    if (!found) {
      if (recent?.length < 5) {
        recent.unshift(product);
        localStorage.setItem("recent-products", JSON.stringify(recent));
      } else {
        recent.pop();
        recent.unshift(product);
        localStorage.setItem("recent-products", JSON.stringify(recent));
      }
    }
  };

  const sortProductOrder = (filteredProducts) => {
    const priorityProducts = [
      "UMEME_PRE_PAID",
      "NATIONAL_WATER",
      "MULTICHOICE",
      "STARTIMES",
      "DSTV",
    ];

    filteredProducts.sort((a, b) => {
      const aPriority = priorityProducts.indexOf(a.code);
      const bPriority = priorityProducts.indexOf(b.code);

      if (aPriority === -1 && bPriority === -1) {
        return 0;
      } else if (aPriority === -1) {
        return 1;
      } else if (bPriority === -1) {
        return -1;
      } else {
        return aPriority - bPriority;
      }
    });

    return filteredProducts;
  };

  const searchForProducts = (value) => {
    if (isSuccess) {
      const filteredProducts = data?.data?.filter(
        (product) => product.category !== "BANK_TRANSFERS"
      );
      const searchByName = filteredProducts?.filter((product) =>
        product?.name?.toLowerCase().includes(value?.toLowerCase())
      );

      const sortedProducts = sortProductOrder(searchByName);

      setProductList(sortedProducts);
    }
  };

  useEffect(() => {
    if (isSuccessSettings === true) {
      setLatestSettings(dataSettings?.data);
    }
  }, [isSuccessSettings]);

  useEffect(() => {
    getRecentProducts();
    setSettingsEnabled(true);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mbilly - Pay your bills</title>
        <link rel="canonical" href="https://mbilly.ug" />
        <meta name="description" content="Pay your bills like a pro." />
      </Helmet>
      <div
        className="header-small"
        style={{
          backgroundImage: "url(/images/background.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/" className="text-primary fw-bold">
              <img
                src="/images/logo.png"
                //width="30"
                height="35"
                className="d-inline-block align-top"
                alt="Mbilly Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="text-white"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/pricing" className="text-white">
                  Home
                </Nav.Link>
                <Nav.Link href="/pricing" className="text-white">
                  Pricing
                </Nav.Link>
                <Nav.Link href="/terms" className="text-white">
                  Terms & Conditions
                </Nav.Link>
                <Nav.Link href="/about" className="text-white">
                  About Us
                </Nav.Link>
              </Nav>
              <Nav className="justify-content-end">
                <Nav.Link href="/help" className="text-white">
                  Help
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="pt-5 pb-5">
          <Row>
            <Col>
              <h1 className="text-center display-2 fw-medium">
                <span className="text-white">Find</span>{" "}
                <span className="text-secondary">Biller</span>
              </h1>
              <p className="mt-3 fs-5 text-danger text-center">
                Search for a business, company, school etc
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Form>
                <InputGroup size="lg">
                  <InputGroup.Text
                    id="inputGroup-sizing-lg"
                    className="bg-white rounded-start-pill"
                  >
                    <Search size={20} color="#64748b" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchParam}
                    onChange={(event) => setSearchParam(event.target.value)}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <Button
                    variant="secondary"
                    className="rounded-end-pill"
                    onClick={() => searchForProducts(searchParam)}
                  >
                    <ArrowRight size={20} color="#ffffff" />
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              md={{ span: 8, offset: 2 }}
              className="d-flex flex-row justify-content-center align-items-center"
            >
              <p className="mt-3 fs-5 text-white text-center me-3">Recent</p>
              <div>
                {recent?.map((item, index) => {
                  return (
                    <Button
                      variant="secondary"
                      size="sm"
                      className="rounded-pill me-2 mb-3 text-white"
                      key={`${index}`}
                      onClick={() => {
                        setShowPay(true);
                        setProductData(item);
                        setRecentProducts(item);
                      }}
                    >
                      {item?.name}
                    </Button>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
        <div className=" blurred-bg mb-0 py-2">
          <Container>
            <Row>
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <p className="mt-3 fs-5 text-white text-center me-3">
                  Pay with
                </p>
                <img
                  src={Visa}
                  alt="VISA"
                  //width="90%"
                  height="20px"
                />
                <img
                  src={Mastercard}
                  alt="Mastercard"
                  //width="90%"
                  height="25px"
                />
                <img
                  src={AirtelMoney}
                  alt="Airtel Money"
                  //width="90%"
                  height="25px"
                />
                <img
                  src={Momo}
                  alt="MTN Mobile Money"
                  //width="90%"
                  height="25px"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container>
        {isLoading && (
          <Spinner animation="grow" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {isError && <p className="text-center">Error loading product list.</p>}
        {isSuccess && (
          <Row className="mt-3">
            {productList?.length > 0 &&
              productList?.map((item, index) => {
                return (
                  <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    className="mt-2"
                    key={item?.code}
                  >
                    <Card className="h-100 card-bg-dark">
                      <Card.Body className="d-flex flex-column">
                        <div className="d-flex flex-column align-items-center">
                          <img
                            src={Functions.generateImage(item?.code)}
                            alt={item?.name}
                            width="70%"
                          />
                          <h4 className="mt-3 text-white text-center">
                            {item?.name}
                          </h4>
                          <p className="text-center text-white small">
                            {item?.category}
                          </p>
                        </div>
                        <Button
                          variant="dark"
                          className="rounded-pill text-white mt-auto"
                          onClick={() => {
                            setShowPay(true);
                            setProductData(item);
                            setRecentProducts(item);
                          }}
                        >
                          Pay
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        )}
      </Container>
      <Pay
        show={showPay}
        setShow={setShowPay}
        productData={productData}
        setProductData={setProductData}
        settings={latestSettings}
      />
      {/* <Container>
        <Row className="mt-3 mb-5">
          <Col md={3} className="mt-2">
            <Card className="h-100 card-bg-dark">
              <Card.Body>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img src={UMEME} alt="Service Image" width="70%" />
                  <h4 className="mt-3 text-white">UMEME</h4>
                  <p className="text-center text-white fs-5">Customer Id:</p>
                  <Form>
                    <Form.Control
                      type="text"
                      placeholder="Input code"
                      style={{ backgroundColor: "#ffffff" }}
                      className="rounded-pill text-center"
                    />
                    <div className="d-grid gap-2 mt-2">
                      <Button
                        variant="dark"
                        className="rounded-pill text-white "
                      >
                        Pay
                      </Button>
                    </div>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Landing;
