import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

const Pricing = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pricing</title>
        <link rel="canonical" href="https://mbilly.ug/contact" />
        <meta name="description" content="Get in touch with us." />
      </Helmet>
      <Header />
      <div className="mt-5">
        <Container>
          <Row>
            <Col>
              <h1 className="text-white">Pricing</h1>
              <p className="mt-3 fs-4 text-white">Transparent pricing</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-3">
          <Col>
            <p>Pricing details here.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pricing;
