import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Header = ({ showSearchBar }) => {
  return (
    <div
      className="header-small"
      style={{
        backgroundImage: "url(/images/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/" className="text-primary fw-bold">
            <img
              src="/images/logo.png"
              //width="30"
              height="35"
              className="d-inline-block align-top"
              alt="Mbilly Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="text-white"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/pricing" className="text-white">
                Home
              </Nav.Link>
              <Nav.Link href="/pricing" className="text-white">
                Pricing
              </Nav.Link>
              <Nav.Link href="/terms" className="text-white">
                Terms & Conditions
              </Nav.Link>
              <Nav.Link href="/about" className="text-white">
                About Us
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
              <Nav.Link href="/help" className="text-white">
                Help
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
