import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
        <link rel="canonical" href="https://mbilly.ug/about" />
        <meta
          name="description"
          content="Our Vision - To increase access to mobility by making shared transport affordable, safe and energy efficient in Africa."
        />
      </Helmet>
      <Header />
      <div className="mt-5">
        <Container>
          <Row>
            <Col md={8}>
              <h1 className="text-white">Our Vision</h1>
              <p className="mt-3 fs-4 text-white">
                To usher in the next generation of secure, affordable, and
                convenient digital payment and financial services
              </p>
              <p className="fs-5 text-white">
                We understand that technology evolves to meet the needs and
                demands of the times, we strive to actively champion, envision
                and implement the next iteration of financial solutions through
                constant renewal, reimagination and rebirth of not just existing
                solutions, but also ourselves as a company.
              </p>
            </Col>
            <Col md={4}>
              <h1 className="text-white">The Mission</h1>
              <p className="mt-3 fs-4 text-white">
                Democratizing digital payment services for all.
              </p>
              <p className="fs-5 text-white">
                In fulfilment of our tagline, we are on a mission to democratize
                digital payment collection and disbursement solutions for
                African businesses or organisations of all sizes. Whether it’s a
                roadside informal kiosk or the multinational enterprise, Culipa
                has something to offer everyone.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-3">
          <Col sm={12}>
            <strong>Company Profile</strong>
            <p>
              Culipa is a Ugandan payment service provider and payment system
              operator offering affordable & secure digital payment solutions.
              The word Culipa is a modification of the Swahili word ‘kulipa’,
              which translates to ‘to pay’.
            </p>
            <strong>Tagline/Motto</strong>
            <p className="fs-3">Commerce Unlimited</p>
            <p>
              We believe in the ability of digital commerce and financial
              services and to uplift lives and contribute to the prosperity of
              Africans. As such, we strive to eliminate all barriers against
              digital financial services adoption through our range of products
              and services that we’re building specifically to meet this
              challenge ambitiously and sustainably.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
