import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Whatsapp, Mailbox, ChatHeart } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import Header from "../components/Header";

const Help = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help</title>
        <link rel="canonical" href="https://mbilly.ug/contact" />
        <meta name="description" content="Get in touch with us." />
      </Helmet>
      <Header />
      <div className="header-small">
        <Container>
          <Row>
            <Col>
              <h1 className="text-white">Help</h1>
              <p className="mt-3 fs-4 text-white">
                Here to help. We are available on the following channels.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mt-3 card-wrapper">
          <Col md={4} className="mt-2">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <ChatHeart size={50} color="#24a2cb" />
                <h4 className="mt-3">Live Chat</h4>
                <p>Available 9AM - 5PM EAT</p>
                <a
                  href=""
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Start Chat
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mt-2">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <Whatsapp size={50} color="#24a2cb" />
                <h4 className="mt-3">WhatsApp</h4>
                <p>+256 393 246 095</p>
                <a
                  href=""
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Send Message
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mt-2">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <Mailbox size={50} color="#24a2cb" />
                <h4 className="mt-3">Email</h4>
                <p>mail@culipa.com</p>
                <a
                  href="mailto:mail@culipa.com"
                  target="_blank"
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  Send Email
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Help;
