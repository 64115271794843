//Image Imports
import UMEME from "../images/umeme.png";
import AIRTEL_MONEY from "../images/airtel_money.png";
import AIRTEL from "../images/airtel.png";
import AZAM_TV from "../images/azamtv.png";
import DSTV from "../images/dstv.png";
import GOTV from "../images/gotv.png";
import MASTERCARD from "../images/mastercard.png";
import MTN_MOBILE_MONEY from "../images/mtn_mobile_money.png";
import MTN from "../images/MTN.jpg";
import NWSC from "../images/nwsc.png";
import ROKE from "../images/roke.png";
import URA from "../images/ura.png";
import ZUKU from "../images/zuku.png";
import LOGO_LIGHT_SMALL from "../images/logo_light_small.png";
import UTL from "../images/utl_logo.png";
import STARTIMES from "../images/startimes.png";

const generateImage = (serviceCode) => {
  let imageURL = null;
  switch (serviceCode) {
    case "STARTIMES":
      imageURL = STARTIMES;
      break;
    case "UTL_AIRTIME":
      imageURL = UTL;
      break;
    case "AIRTEL_UG_INTERNET":
      imageURL = AIRTEL;
      break;
    case "AIRTEL_UG_VOICE_BUNDLES":
      imageURL = AIRTEL;
      break;
    case "AIRTEL_UG_AIRTIME":
      imageURL = AIRTEL;
      break;
    case "AZAM_TV":
      imageURL = AZAM_TV;
      break;
    case "DSTV":
      imageURL = DSTV;
      break;
    case "MULTICHOICE":
      imageURL = DSTV;
      break;
    case "GO_TV":
      imageURL = GOTV;
      break;
    case "MTN_UG_AIRTIME":
      imageURL = MTN;
      break;
    case "MTN_UG_INTERNET":
      imageURL = MTN;
      break;
    case "MTN_UG_VOICE_BUNDLES":
      imageURL = MTN;
      break;
    case "NATIONAL_WATER":
      imageURL = NWSC;
      break;
    case "UMEME_PRE_PAID":
      imageURL = UMEME;
      break;
    case "ROKE_TELECOM_UG_INTERNET":
      imageURL = ROKE;
      break;
    default:
      imageURL = LOGO_LIGHT_SMALL;
  }

  return imageURL;
};

const setMsisdnLabel = (serviceCode) => {
  let label = null;
  switch (serviceCode) {
    case "STARTIMES":
      label = "Startimes Code";
      break;
    case "UTL_AIRTIME":
      label = "UTL Phone Number";
      break;
    case "AIRTEL_UG_INTERNET":
      label = "Airtel Phone Number";
      break;
    case "AIRTEL_UG_VOICE_BUNDLES":
      label = "Airtel Phone Number";
      break;
    case "AIRTEL_UG_AIRTIME":
      label = "Airtel Phone Number";
      break;
    case "AZAM_TV":
      label = "Azam TV Code";
      break;
    case "DSTV":
      label = "DSTV Code";
      break;
    case "MULTICHOICE":
      label = "Multichoice Code";
      break;
    case "GO_TV":
      label = "GO TV Code";
      break;
    case "MTN_UG_AIRTIME":
      label = "MTN Phone Number";
      break;
    case "MTN_UG_INTERNET":
      label = "MTN Phone Number";
      break;
    case "MTN_UG_VOICE_BUNDLES":
      label = "MTN Phone Number";
      break;
    case "NATIONAL_WATER":
      label = "NWSC Account Number";
      break;
    case "UMEME_PRE_PAID":
      label = "YAKA Meter Number";
      break;
    case "ROKE_TELECOM_UG_INTERNET":
      label = "ROKE Telecom Number";
      break;
    default:
      label = "Account Number";
  }

  return label;
};

const setMsisdnPlaceholder = (serviceCode) => {
  let label = null;
  switch (serviceCode) {
    case "STARTIMES":
      label = "Startimes Code";
      break;
    case "UTL_AIRTIME":
      label = "UTL Phone Number";
      break;
    case "AIRTEL_UG_INTERNET":
      label = "0701234567";
      break;
    case "AIRTEL_UG_VOICE_BUNDLES":
      label = "0701234567";
      break;
    case "AIRTEL_UG_AIRTIME":
      label = "0701234567";
      break;
    case "AZAM_TV":
      label = "Azam TV Code";
      break;
    case "DSTV":
      label = "DSTV Code";
      break;
    case "MULTICHOICE":
      label = "Multichoice Code";
      break;
    case "GO_TV":
      label = "GO TV Code";
      break;
    case "MTN_UG_AIRTIME":
      label = "0771234567";
      break;
    case "MTN_UG_INTERNET":
      label = "0771234567";
      break;
    case "MTN_UG_VOICE_BUNDLES":
      label = "0771234567";
      break;
    case "NATIONAL_WATER":
      label = "NWSC Account Number";
      break;
    case "UMEME_PRE_PAID":
      label = "YAKA Meter Number";
      break;
    case "ROKE_TELECOM_UG_INTERNET":
      label = "ROKE Telecom Number";
      break;
    default:
      label = "Account Number";
  }

  return label;
};

const checkNumber = (value) => {
  let validatePoint = value?.substring(0, 3);
  if (
    validatePoint !== "070" &&
    validatePoint !== "074" &&
    validatePoint !== "075" &&
    validatePoint !== "076" &&
    validatePoint !== "077" &&
    validatePoint !== "078"
  ) {
    return false;
  } else {
    return true;
  }
};

const getProviderCharge = (value, charges) => {
  if (charges?.serviceCharges?.length > 0) {
    //calculate charges
    let feesTier = charges?.serviceCharges;
    let charge = 0;
    let parsedValue = parseInt(value);

    for (let i = 0; i < feesTier?.length; i++) {
      let min = parseInt(feesTier[i].min_amount);
      let max = parseInt(feesTier[i].max_amount);

      if (parsedValue >= min && parsedValue <= max) {
        charge = feesTier[i].fee;
        break;
      }
    }
    //return Math.ceil(charge);
    return charge;
  } else {
    return 0;
  }
};

const getMbillyCharge = (value, rate) => {
  console.log("rate", rate);
  console.log("value", value);
  console.log("rate", rate / 100);
  const charge = parseInt(value) * (rate / 100);
  console.log(charge);
  return charge;
};

export default {
  generateImage,
  checkNumber,
  getProviderCharge,
  getMbillyCharge,
  setMsisdnLabel,
  setMsisdnPlaceholder,
};
